<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/goods2' }">商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>超市商品列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <el-form
        ref="form"
        :model="goodsForm"
        label-width="80px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="门店名称">
          <el-input
            v-model="goodsForm.shopName"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        type="index"
        :header-cell-style="{ background: '#CD0550', color: '#fff' }"
      >
        <el-table-column prop="id" label="ID"> </el-table-column>
        <el-table-column prop="shop.name" label="门店名称"> </el-table-column>
        <el-table-column prop="good_series.good_name" label="品类名称"> </el-table-column>
        <el-table-column prop="original_price" label="门市价格"></el-table-column>
        <el-table-column prop="price" label="销售价格"></el-table-column>
        <el-table-column prop="platform_service_fee" label="平台服务费"></el-table-column>
        <el-table-column prop="good_image" label="图片" width="190px"> 
            <template slot-scope="scope">
                <img :src="imgUrl+scope.row.good_image" width="170px" height="80px" alt="">
            </template>
        </el-table-column>
      </el-table>
      <div class="page-num">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="prev,pager,next, total,sizes,jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { imgUrl} from "@/utils/http.js"
import {supermarketGoodlist} from "@/api/goods.js"
export default {
  data() {
    return {
      imgUrl:imgUrl,
      goodsForm: {
        shopName: "",
      },
      currentPage: 1,
      pageSizes: [10, 20,50],
      pageSize: 10,
      total: 0,
      tableData:[],
      active:1,
    };
  },
  methods: {
    //获取商品列表
    async getGoodsList(){
      let res=await supermarketGoodlist({
        shop_name:this.goodsForm.shopName,
        page:this.currentPage,
        limit:this.pageSize
      })
      this.tableData=res.data.data.list
      this.total=res.data.data.total_count
    },
    //重置
    emptySearch() {
      this.goodsForm = {
        shopName: "",
      };
      this.currentPage=1
      this.getGoodsList()
    },
    //搜索
    search() {
      this.currentPage=1
      this.getGoodsList()
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize=e
      this.getGoodsList()
    },
     //切换页
    handleCurrentChange(e) {
      this.currentPage=e
      this.getGoodsList()
    }
  },
  created(){
    this.getGoodsList()
  }
}
</script>

<style lang="less" scoped>
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    // background: rgba(246, 219, 229, 0.2);
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
</style>