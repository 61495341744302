import request from "@/utils/request.js"
import Qs from "qs"

//修改生鲜商品

export const freshChangechainGood=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/supermarket/chainGood/freshChange',
    data: Qs.stringify(data)
  });
  } 

//修改非生鲜商品

export const changechainGoodsupermarket=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/supermarket/chainGood/change',
    data: Qs.stringify(data)
  });
  } 

//获取商品列表
export const getGoodsList_api=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/good/list',
      params,
    });
    } 
  //获取所有的商品分类
export const getAllShopCategory_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v1/supermarket/chainGood/getAllShopCategory',
    params,
  });
  } 
  //获取日志列表
export const getLogRecord_api=params=>{
  return  request({
    method: 'get',
    url: 'api/v2/order/getLogRecord',
    params,
  });
  } 
//修改商品信息
export const changeGoodsInfo_api=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/good/update',
    data: Qs.stringify(data)
  });
  } 
//超市修改商品信息
export const changeGoodsInfo_api1=data=>{
  return  request({
    method: 'post',
    url: 'api/v1/store/good/update',
    data: Qs.stringify(data)
  });
  } 
  //商品统计
  export const GoodsStatistics_api=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/good/statistics/list',
      params,
    });
    } 
     //门店商品统计
  export const GoodsStatistics_api1=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/store/good/statistics/list',
      params,
    });
    } 
  //删除商品

  export const chainGoodDelete_api=data=>{
    return  request({
      method: 'post',
      url: 'api/v1/chainGood/delete',
      data: Qs.stringify(data)
    });
    } 
    //删除商品(超市)
    export const chainGoodDelete_api1=data=>{
      return  request({
        method: 'post',
        url: 'api/v1/supermarket/chainGood/freshDelete',
        data: Qs.stringify(data)
      });
      } 
  //删除商品(连锁)

  export const chainGooddelete=data=>{
    return  request({
      method: 'post',
      url: 'api/v1/supermarket/chainGood/delete',
      data: Qs.stringify(data)
    });
    } 
  
    
    //连锁超市商品列表
    export const supermarketGoodlist=params=>{
      return  request({
        method: 'get',
        url: 'api/v1/supermarketGood/list',
        params
      });
      } 
  //
  
  export const goodSerieslist=params=>{
    return  request({
      method: 'get',
      url: 'api/v1/goodSeries/list',
      params
    });
    } 